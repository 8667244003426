@import '../mixins/responsive';

.customize {

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(0,0,0, 0.4);
  opacity: 1;
  transition: all 300ms ease-in-out;


  &--show {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &--closed {
    opacity: 0;
    // display: none;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0);
  }

  &-cta {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    text-decoration: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 300ms ease-in-out;

    &--show {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    &.button {
      font-size: 12px;
      padding: 4px 14px;
      border-color: #666;

      @include respond-to('medium') {

        border-radius: 40px;
        font-size: 16px;
        line-height: 29px;
        font-size: 14px;
        padding: 8px 18px;

      }
    }
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      width: 24px;
      height: 24px;
      @include respond-to('medium') {
        width: 36px;
        height: 36px;
      }
    }

    @include respond-to('small') {
      right: 70px;
      top: 60px;
    }
  }

  &__label {
    margin-bottom: 0;
    min-width: 220px;
    font-size: 14px;

    @include respond-to('medium') {
      font-size: 16px;
      margin-bottom: 3rem;
    }
  }

  &__personality-wrapper {
    margin-bottom: 2rem;

    @include respond-to('medium') {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 4rem;
    width: 100%;
    @include respond-to('medium') {
      margin: 0 4rem;
    }
    > div {
      flex: 1 1 33%;
      @include respond-to('medium') {
        padding: 0 1.5rem;
      }
    }

    @include respond-to('medium') {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__colors {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 2rem;
    width: 100%;

    @include respond-to('medium') {
      margin: 2rem 0;
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1rem;
      row-gap: 1rem;
      @include respond-to('medium') {
        column-gap: 26px;
        row-gap: 26px;
        // margin-top: 2rem;
      }
    }
  }

  &__swatch {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-sizing: content-box;
    cursor: pointer;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    border: 2px solid transparent;
    // overflow: hidden;

    svg {
      height: 33px;
      width: 33px;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      // margin: 3%;

      @include respond-to('small') {
        // margin: -3%
      }

      @include respond-to('large') {
        // margin: 6%
      }
    }


    &--selected {
      border: 2px solid rgba(255, 0, 0, 1);

      svg {
        display: block;
      }
    }

    &--black {
      background-image: url('../assets/color-black.png');

      // &:not(.appearance__swatch--selected) {
      //   border-color: #999;
      // }
    }

    &--black-white {
      background-image: url('../assets/color-black-white.png');
    }

    &--white {
      background-image: url('../assets/color-white.png');
    }

    &--orange {
      background-image: url('../assets/color-orange.png');
    }

    &--brown {
      background-image: url('../assets/color-brown.png');
    }

    &--gray {
      background-image: url('../assets/color-gray.png');
    }
  }

}