@import '../mixins/responsive';
.personality {

  &__text,
  &__choices {
    flex: 1 1 auto;
  }

  &__heart-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    @include respond-to('medium') {
      width: 2rem;
      height: 2rem;
    }
  }
  &__heart {
    height: 12px;
    width: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    @include respond-to('medium') {
      height: 15px;
      width: 15px;
    }
  }

  &__choice input:checked+.personality__heart-container .personality__heart {
    display: block;
  }

  &__choices {
    margin-top: 1rem;
    @include respond-to('small') {
      // margin-top: 0;
    }
    @include respond-to('large') {
      // margin-bottom: 26px;
    }
    @include respond-to('medium') {
      margin-top: 0rem;
      width: 220px
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @include respond-to('small') {
      flex-direction: row;
    }
  }

  &__choice {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;
    margin-bottom: 1rem;

    input {
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid white;
      border-radius: 50%;
      cursor: pointer;
      height: 1.5rem;
      margin: 0;
      position: relative;
      width: 1.5rem;
      @include respond-to('medium') {
        border: 3px solid white;
        height: 2rem;
        width: 2rem;

      }

      &:checked {
        border-color: rgba(255, 0, 0, 1);
      }
    }

    label {
      cursor: pointer;
      // font-size: 20px;
      // line-height: 50px;
      margin-left: 1rem;
      font-size: 14px;
      @include respond-to('medium') {
        font-size: 16px;
        // font-size: 18px;
        // line-height: 3rem;
        // margin-left: 1rem;
      }
    }
  }
}