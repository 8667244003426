@import '../mixins/responsive';

.controls {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  @include respond-to('small') {
    margin-top: 0px;
    justify-content: flex-start;
  }

  @include respond-to('large') {
    margin-top: 0px;
    // justify-content: center;
  }

}