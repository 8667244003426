.unity {
  &__canvas {
    width: 100% !important;
    height: 100vh !important;
  }
  &--loaded::after {
    display: none;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
  }
}
#unity-container {
  height: 100vh;
  left: 0;
  overflow: hidden;
  top: 0;
  transform: none;
  width: 100%;
}
