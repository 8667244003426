@import '../mixins/responsive';

.chat {
  align-items: center;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 20px;

  @include respond-to('small') {
    // padding: 130px;
  }

  &--hide-ui {

    .chat__response,
    .chat__bottom-ui {
      display: none;
    }
  }

  &--connected {
  }

  &__prevMessage {
    background: rgba(0,0,0,0.4);
    border: 1px solid #444444;
    border-radius: 60px;
    padding: 1rem 30px;
    margin-bottom: 1rem;
    display: table;
    margin: 0 auto 1rem;
    max-width: calc(66.52vw - 60px);
    transition: all 200ms ease-out;

    font-size: 14px;
    line-height: 18px;

    @include respond-to('small') {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .formality__wrapper {
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    @include respond-to('small') {
      width: 35vw;
      margin: 0 auto;
    }
    svg {
      width: 100%;
      margin-bottom: 28px;
      @include respond-to('small') {
        display: block;
        margin-bottom: 46px;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
      }
    }
    .chat__text {
      font-size: 14px;
      line-height: 20px;
      width: auto;
      @include respond-to('small') {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  &__connect-error {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    h3 {
      margin-bottom: 32px;
      width: 50%;
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 200ms ease-out;
    p {
      background-color: white;
      border-radius: 30px;
      color: black;
      display: inline-block;
      font-size: 15px;
      line-height: 20px;
      margin: 0 auto 25px;
      padding: 12px 28px;
      text-align: center;
    }
  }

  &__response {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    &-text {
      display: block;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      width: 60vw;
      ::selection {
        background-color: black;
      }
      // user-select: none;
      @include respond-to('small') {
        width: 46.3vw;
      }
      @include respond-to('medium') {
        font-size: 17px;
        line-height: 22px;
      }
    }
  }

  &__text {
    width: 444px;

    h1 {
      font-size: 150px;
      line-height: 150px;
      margin-bottom: 44px;
    }

    span {
      display: inline-block;

      text-align: center;
      width: 100%;
    }

    svg {
      display: inline;
      width: 28px;
      margin-left: 4px;
    }
  }

  .input__submit {
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 8px;
    margin-right: 4px;
    position: relative;
    font-size: 24px;
  }


  .input__wrapper {
    align-self: center;
    align-items: center;
    background: #000000;
    border: 1px solid #666666;
    border-radius: 30px;
    margin: 0 auto 60px;
    display: flex;
    height: 48px;
    width: 50px;
    transition: width 1s ease-in-out 200ms, opacity 0.4s ease-out;
    opacity: 0;

    @include respond-to('small') {
      bottom: 80px;
    }

    input {
      background-color: transparent;
      border-radius: 0;
      border: none;
      flex: 1 0 auto;
      padding: 0px 0 2px 24px;
      transition: border-left 0.5s ease-out 0.8s;

      &::placeholder {
        transform: translateY(50px);
        transition: transform 0.5s ease-out 1s;
      }
    }

    .heart-container {
      position: relative;
    }

    svg {
      opacity: 0;
      transition: transform 0.5s ease-out 1s;
      cursor: pointer;
      margin:0 10px;
      &.animating {
        z-index: 10;

        circle {
          fill: transparent;
          stroke: transparent;
          stroke-width: 0;
        }

        path {
          fill: red;
        }
      }

      &.active circle {
        fill: red;
        stroke: red;
      }
      circle {
        transition: fill 0.3s ease-out 0s, stroke 0.3s ease-out 0s;
        fill: transparent;
        stroke: white;
        stroke-width: 1;
      }
    }

    &--connected {
      width: 90%;
      opacity: 1;

      @include respond-to('small') {
        width: 66.52vw;
      }

      input {
        &::placeholder {
          transform: translateY(0px);
        }
      }
      .input__char-count {
        font-size: 10px;
        line-height: 13px;
      }

      svg {
        opacity: 1;
      }
    }
  }

}

