.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  left: 50%;
  bottom: 50px;
  width: 100px;
  height: 100px;
  transform: translateX(-50%);
  transition: opacity 200ms ease-out;
  z-index: 10;
  &--loaded {
    opacity: 0;
  }
  &__lottie {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
}