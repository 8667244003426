@import '../mixins/responsive';

html, body, input {
  font-family: 'IBM Plex Mono', monospace;
  color: white;
}

p {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 32px;
  @include respond-to('small') {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 48px;
  }
}

h1 {
  font-size: 82px;
  @include respond-to('small') {
    font-size: 100px;
  }
  @include respond-to('large') {
    font-size: 164px;
  }
}

h3 {
  font-size: 44px;
  line-height: 48px;

  @include respond-to('small') {
    font-size: 60px;
    line-height: 64px;
  }

  @include respond-to('large') {
    font-size: 78px;
    line-height: 84px;
  }
}

h5 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
  @include respond-to('small') {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 28px;
  }
}

a {
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: white;
  }
}