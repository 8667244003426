@import '../mixins/responsive';

.about {
  align-items: center;
  background: #6400FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  bottom: 0;
  padding: 0;
  top: 0;
  // height: 100vh;
  transform: translate3d(100vw, 0, 1px);
  transition: transform 0.8s cubic-bezier(0.65, 0, 0.35, 1);
  will-change: transform;

  &--open {
    transform: translate3d(0, 0, 1px);
  }

  @include respond-to('large') {
    display: block;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      width: 24px;
      height: 24px;

      @include respond-to('medium') {
        width: 36px;
        height: 36px;
      }
    }

    @include respond-to('small') {
      right: 70px;
      top: 60px;
    }
  }

  &__cta {
    align-items: center;
    color: #FFFFFF;
    display: flex;
    font-size: 12px;
    line-height: 15px;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    margin-top: 10px;
    text-decoration: none;

    @include respond-to('medium') {
      font-size: 14px;
      line-height: 22px;
      margin-top: 13px;
    }

    @include respond-to('small') {
      // right: 80px;
      // top: 80px;
    }

    @include respond-to('large') {
      // right: 52px;
      // top: 46px;
    }
  }

  &__text {
    padding-left: 18px;
    width: 80%;

    @include respond-to('small') {
      padding: 80px;
      width: 55%;
    }

    @include respond-to('large') {
      padding: 100px;
      width: 55%;
    }
  }

  &__pda-logo {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 200px;
    width: 200px;

    @include respond-to('small') {
      height: 300px;
      width: 300px;
      // bottom: 300px;
    }

    @include respond-to('large') {
      height: 491px;
      width: 491px;
      // bottom: 491px;
    }
  }
}