$breakpoints: ('small': (min-width: 767px),
  'medium': (min-width: 992px),
  'large': (min-width: 1200px)) !default;


/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {

  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {

    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  @if $breakpoint == 'short' {
    @media screen and (max-height: 690px) and (min-width: 767px) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  // @else {
  //   @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
  //   +"Available breakpoints are: #{map-keys($breakpoints)}.";
  // }
}