@import '../mixins/responsive';

.appearance {

  &__text,
  &__colors {
    flex: 1 1 50%;
  }

  &__colors {
    display: flex;
    flex-direction: column;
    margin: 32px 0;
    @include respond-to('small') {
      margin: 0;
    }
    .controls {

      @include respond-to('large') {
        justify-content: center;
      }
    }
    &-list {
      column-gap: 26px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0 26px 26px 26px;
      row-gap: 26px;


    }
  }

  &__swatch {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-sizing: content-box;
    cursor: pointer;
    // overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    border: 4px solid transparent;

    svg {
      height: 33px;
      width: 33px;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      margin: 3%;
      @include respond-to('small') {
        margin: -3%
      }
      @include respond-to('large') {
        margin: 6%
      }
    }


    &--selected {
      border: 4px solid rgba(255,0,0,1);
      svg {
        display: block;
      }
    }

    &--black {
      background-image: url('../assets/color-black.png');

      // &:not(.appearance__swatch--selected) {
      //   border-color: #999;
      // }
    }

    &--black-white {
      background-image: url('../assets/color-black-white.png');
    }

    &--white {
      background-image: url('../assets/color-white.png');
    }

    &--orange {
      background-image: url('../assets/color-orange.png');
    }

    &--brown {
      background-image: url('../assets/color-brown.png');
    }

    &--gray {
      background-image: url('../assets/color-gray.png');
    }
  };



  &__wrapper {
    display: flex;
    flex-direction: column;
    @include respond-to('small') {
      flex-direction: row;
    }
  }
}