@import '../mixins/responsive';

html, body {
  background-color: black;
  // background-color: grey;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
  width: 100%;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
}

section {
  // padding: 20px;
  min-height: 100%;
  min-height: -webkit-fill-available;

  padding: 18px;
  position: relative;

  @include respond-to('small') {
    padding: 80px;
  }

  @include respond-to('large') {
    padding: 100px;
  }


  & > h5:first-child {
    visibility: hidden;
    @include respond-to('small') {
      visibility: visible;
    }
  }

}

#catgpt {
  &-app {
    // height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    left: 0px;
    position: absolute;
    overflow: hidden;
    top: 0px;
    width: 100%;
  }
}

.catgpt__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  left: 0;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
}

.catgpt__video-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.button {
  align-items: center;
  background-color: transparent;
  border: thin solid white;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  justify-content: center;
  line-height: 26px;
  padding: 6px 20px;
  transition: all 200ms ease-out;

  @include respond-to('medium') {
    border-radius: 40px;
    font-size: 22px;
    line-height: 29px;
    padding: 12px 40px;
  }

  &:hover {
    background-color: white;
    color: black;
  }
  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

input[type="text"] {
  background-color: black;
  border-radius: 40px;
  border: none;
  color: #999999;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 0 10px 14px;
  outline: none;
  border: thin solid #666;
  @include respond-to('small') {
    font-size: 14px;
    line-height: 20px;
    padding: 20px 0 20px 24px;
  }
  @include respond-to('medium') {
    font-size: 17px;
    line-height: 22px;
    padding: 22px 0 22px 28px;
  }
  @include respond-to('short') {
    font-size: 16px;
    line-height: 18px;
    padding: 18px 0 18px 24px;;
  }
}


@keyframes blink {
  0% { visibility: hidden }
  49% { visibility: hidden }
  50% {visibility: visible}
  100% {visibility: visible}
}

.blink {
  animation: blink 1s infinite;
}
